/* You can add global styles to this file, and also import other style files */
@use "css-star-rating/scss/star-rating";

@font-face {
  font-family: "Trebuchet MS";
  font-style: normal;
  font-weight: normal;
  src: local("Trebuchet MS"), url("./assets/fonts/trebuc.woff") format("woff");
}

@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Fira Sans"),
    url("./assets/fonts/FiraSans-Regular.ttf") format("woff");
}

.filter-container {
  background: #f5f7f9;
}

.btn-outline-primary {
  color: #000 !important;
  border-color: #ff6600 !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background: #ff6600 !important;
}

.brand-bg-color {
  background: #ff6600;
}

.brand-bg-gradient-color {
  background: linear-gradient(205.17deg, #ff6600 10.93%, #ff9148 116.08%);
}

.brand-top-nav-bg-color {
  box-shadow: 0px 5px 20px 0px #00000008;
}

.mat-mdc-input-element *,
.mdc-floating-label *,
.mdc-text-field__input,
.mat-mdc-option *,
.mat-mdc-select-value-text * {
  font-family: "IBM Plex Sans" !important;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4,
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p,
.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-family: "IBM Plex Sans" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "IBM Plex Sans" !important;
}
h1 {
  font-size: 40px;
}
.lf-table {
  th {
    text-align: center;
  }
}

.lf-title {
  color: #000000;
  font-weight: bolder;
}

body {
  font-family: "IBM Plex Sans", "Trebuchet MS", "Helvetica Neue", Helvetica,
    Arial, sans-serif !important;
  color: #53565a !important;
  margin: 0;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #ff7b23 !important;
}

.test-runner {
  background: #fff
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABlJREFUeNpi+P//PwMIA4EAggEFcAZAgAEAHHwJKIgcPX8AAAAASUVORK5CYII=);
}

.dial-code .mdc-text-field {
  padding: 0px 7px !important;
}

.mat-mdc-form-field-hint-wrapper {
  padding-left: 0 !important;
}

.mat-mdc-form-field-error-wrapper {
  padding-left: 0 !important;
}

.snack-bar-danger .mdc-snackbar__surface {
  background: #f91707e2 !important;
}

.snack-bar-success .mdc-snackbar__surface {
  background: #ff6600 !important;
}

.sticky-top {
  z-index: 20 !important;
}

.lff-white .mat-mdc-text-field-wrapper,
.angular-editor-wrapper {
  background: white !important;
}

.form-check-input {
  width: 1.2em;
  box-shadow: none !important;
  height: 1.2em;
  background-size: unset;
}

.form-check-input:checked {
  border-color: rgba(0, 0, 0, 0.25) !important;
  background-image: url(data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAAsRAAALEQF/ZF+RAAAAFnRFWHRDcmVhdGlvbiBUaW1lADA3LzIyLzEzi6Ei/gAAABh0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzT7MfTgAAAXNJREFUOE+dk8+KglAUxn2tGeYZereWJRFugloEJYbSE7SYZhWCUZtokxKBJSjUGX+nrmPkpjnwXeSc8333/LlaImJ1Op0Ki8XiKwiC5Wg0yrrdrgC+fd9fEjN58MCTAEmO48h8Ppftdiun00mx2WykFJV+vy9lzk9dpCJzy2QykTRNS3+zITYcDrUiw9OjVP/2PE/yPH+kilaAIODbWJZl6oOjAvRl23Z18/l8lna7La1W6wn4iGFJkggcuFZ5c0R/xprIBsSw2+0m0+lU4Grvu91OA5TaRKzDtBNFkc6CGVS9o9pEqoMcjHZYsQoURaHOdwQYJlxtYb/fq/OdFtbr9b2F/wzxer3KbDa7D7FcxScrYTXY5XJpFMFHDKPixxo/qofkum61Z4xS6ReYsjHey3g8/ntIHIB+BoOBxHH8SH21w+Eg5JBreC8/U6/XE6pZrVba1vF4lDAM1UfM3Azg6lEXYSYkcUv9d8ZHzOTdBcT6BV683+pUXt1WAAAAAElFTkSuQmCC) !important;
}

.move-to-top {
  margin-top: -450px !important;
}

.sb-topnav {
  height: 70px !important;
}

.brand-pd {
  padding: 11px !important;
}

.hide-down-arrow::after {
  display: none !important;
}

.lf-card {
  box-shadow: 0px 2.4273858070373535px 7.2821574211120605px 0px #00000024;
}

.lf-table {
  tr {
    td,
    th {
      border-radius: 5px;
      color: black;
      padding: 8px 15px;
      background: #eaeaea;
      vertical-align: middle;
      border-width: 4px;
      border-color: #f5f7f9;
    }

    th:first-child,
    td:first-child {
      text-align: center;
      border-left-width: 0;
    }

    th:last-child,
    td:last-child {
      border-right-width: 0;
    }

    th {
      background-color: #fff;
      padding: 15px;
    }
  }
}

.dialog-header {
  width: 100%;
  height: 50px;
  background: #ff8432;

  .dialog-title {
    padding-top: 12px;
    padding-left: 14px;
    color: white;

    .link {
      cursor: pointer;
    }
  }
}

.mat-dialog-sm {
  .mat-mdc-dialog-container {
    height: 400px;
    width: 30vw;

    .mat-mdc-dialog-surface {
      border-radius: 20px !important;
    }
  }
}

.dash-dialog,
.mat-dialog-md {
  .mat-mdc-dialog-container {
    height: 400px;
    width: 60vw;

    .mat-mdc-dialog-surface {
      background: #f6f6f6;
      border-radius: 20px !important;
    }
  }
}

label {
  font-family: IBM Plex Sans !important;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
  width: 12px !important;
}

.login-form {
  margin-top: 24px;
}

h1 {
  font-size: 40px;
}

.left-pageHeading h1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
}

.left-pageHeading p {
  color: rgba(31, 29, 40, 1);
  font-size: 18px;
}

.login-bottom h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.login-bottom_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label {
  color: #344054;
  font-weight: 600;
}

.form-control {
  // border: 1.1039px solid #D0D5DD !important;
  box-shadow: 0px 1.1039px 2.20779px rgba(16, 24, 40, 0.05);
  // border-radius: 8.83117px !important;
}

.congrat h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 29px;
}

.congrat p {
  font-size: 13px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 25px;
}

.congrat a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: rgba(255, 132, 50, 1);
  padding: 7px;
  border-radius: 4px;
}

.badge-primary {
  background-color: rgba(255, 132, 50, 1);
}

.rating {
  outline: none;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: rgba(255, 132, 50, 1) !important;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: rgba(255, 132, 50, 1) !important;
}

.blockscreen {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  &.white {
    .mat-mdc-dialog-container {
      .mdc-dialog__surface {
        background-color: #fff !important;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
