@charset "UTF-8";

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff6600;
  --secondary: #e71d36;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', IBM Plex Sans, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1 {
  font-size: 40px !important;
  font-weight: 700;
}

h2 {
  font-size: 30px !important;
  font-weight: 600;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: IBM Plex Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 1);
}

.btn-primary {
  color: #fff;
  background-color: #ff6600;
  border: none;
}

a {
  text-decoration: none;
  color: #0b1b35;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-outline-primary {
  color: #ff6600;
  border-color: #ff6600;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #ff6600;
  border-color: #ff6600;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ff6600;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff6600;
  border-color: #ff6600;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 202, 139, 0.5);
}

.btn-outline-secondary {
  color: #e71d36;
  border-color: #e71d36;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #e71d36;
  border-color: #e71d36;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 54, 99, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #e71d36;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #e71d36;
  border-color: #e71d36;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.ml-lg-auto,
.mx-lg-auto {
  margin-left: auto !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

:root {
  --theme-color: #e40000;
  --primary-color: #3f3d56;
  --primary-light: #fcf1e7;
  --primary-dark: #ffc945;
  --secondary-color: #ff6600;
  --font-color: #a1a1b0;
  --bg-color: #fff;
  --heading-color: #1c1c1c;
  --short-heading-color: #8a898e;
  --footer-color: #1e2833;
  --footer-cpy-color: #1e2833;
  --border-radius: 30px;
  --border-radius-full: 15px;
  --border-color: transparent;
  --border-color-light: #eee;
  --iframe-filter: grayscale(0%);
  --bg-grey: #f4f4f4;
  --bg-light: #fbfbfb;
  --bg-dark-color: #fff;
}

[data-theme='dark'] {
  --theme-color: #e40000;
  --primary-color: #3f3d56;
  --primary-light: #ffc945;
  --secondary-color: #ff6600;
  --primary-dark: #00070c;
  --font-color: #b7b7b7;
  --bg-color: #131617;
  --heading-color: #fdfffc;
  --short-heading-color: #8a898e;
  --footer-color: #1e2833;
  --footer-cpy-color: #1e2833;
  --border-color: rgba(255, 255, 255, 0.2);
  --border-color-light: rgba(255, 255, 255, 0.2);
  --iframe-filter: grayscale(100%);
  --bg-grey: #0b0d0e;
  --bg-light: #27282b;
  --bg-dark-color: #27282b;
}

/* movetop styling */
#movetop {
  position: fixed;
  bottom: 90px;
  right: 15px;
  z-index: 9;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  width: 40px;
  height: 40px;
  background: var(--secondary-color);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  padding: 0;
}

#movetop:hover {
  opacity: 0.8;
}

/* //movetop styling */

/* header */
/* navigation styling */
.navbar .navbar-brand {
  margin: 0;
  padding: 0;
  font-size: 32px;
  color: var(--heading-color);
}

.navbar .navbar-brand span {
  font-weight: 100;
}

a.navbar-brand i {
  font-size: 32px;
  color: var(--secondary-color);
}

a.navbar-brand:hover {
  color: var(--secondary-color);
}

.navbar-expand-lg .navbar-nav .nav-item {
  padding: 0 12px;
  transition: all 0.5s ease;
  position: relative;
}

.navbar-expand-lg .navbar-nav .show > .nav-link,
.navbar-expand-lg .navbar-nav .active > .nav-link,
.navbar-expand-lg .navbar-nav .nav-link.show,
.navbar-expand-lg .navbar-nav .nav-link.active,
.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: var(--secondary-color);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--secondary-color);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 10px 0;
  font-size: 18px;
  color: var(--heading-color);
  letter-spacing: 0.5px;
}

.navbar-expand-lg a.nav-link.donate.btn.btn-style {
  display: block;
  padding: 0px 30px;
  color: #fff;
  height: 46px;
  font-size: 16px;
  line-height: 42px;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s ease-in;
  border-radius: 4px;
}

a.dropdown-item {
  font-size: 16px;
  color: var(--heading-color);
  letter-spacing: 0.5px;
  padding: 5px 24px;
}

a.dropdown-item:hover {
  color: var(--secondary-color);
  background: none;
}

a.dropdown-item.active {
  background: transparent;
  color: var(--secondary-color);
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  transform: translateX(-50px);
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  box-shadow: rgb(12 12 12 / 6%) 0px 1px 20px 0px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu:before {
  content: '';
  width: 14px;
  height: 14px;
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  display: block;
  border-right: none;
  border-bottom: none;
  transform: translateX(-50%) rotateZ(45deg);
  position: absolute;
  top: -7px;
  left: 49%;
  z-index: 1001;
}

header {
  background-color: transparent;
  padding: 0px;
  transition: all 0.4s ease 0s;
  display: grid;
  align-items: center;
  /* box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05); */
}

header.nav-fixed {
  padding: 0px;
  border-bottom: none;
  z-index: 1000 !important;
  background-color: var(--bg-color);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  /* for z-index:10 to display gallery popup */
}

nav.navbar.navbar-expand-lg.stroke {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--bg-color);
}

header.nav-fixed nav.navbar.navbar-expand-lg.stroke {
  box-shadow: none;
  border-radius: 0px;
}

.navbar-nav .show,
.navbar-nav .nav-link.show {
  color: #7c6a6a;
}

nav ul li {
  display: inline-block;
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

nav ul li a:hover {
  color: #fff;
}

.list li {
  font-size: 16.35px;
}

.navbar-nav .nav-link:focus {
  color: #eee;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  min-width: 12rem;
  padding: 1rem 0;
}

.nav-fixed .navbar-expand-lg .navbar-nav .nav-link {
  color: var(--heading-color);
  opacity: 0.95;
  font-size: 18px;
}

.nav-fixed .navbar-brand {
  color: var(--heading-color);
}

.nav-fixed .phone a {
  color: #fff;
}

.nav-fixed .phone a span {
  opacity: 1;
  color: #fff;
}

.nav-fixed .navbar-expand-lg .navbar-nav li.nav-item.active .nav-link {
  color: var(--secondary-color);
}

.nav-fixed .navbar-expand-lg .navbar-nav li.nav-item .nav-link:hover {
  color: var(--secondary-color);
}

.nav-fixed .navbar-expand-lg .navbar-brand span.fa {
  color: var(--theme-color);
}

.nav-fixed .navbar-expand-lg .navbar-brand span.sub-logo {
  color: #272121;
}

.navbar .search-right {
  margin: 0 20px;
}

.navbar .search-right a {
  font-size: 15px;
  padding: 8px 27px;
}

.navbar form.search-box {
  display: flex;
}

.navbar .search-right .popup form .input-search {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #555;
  border: none;
  background: #fff;
  padding: 18px 20px;
  width: 100%;
  display: flex;
  border-radius: 30px 0px 0px 30px;
}

.navbar .search-right .popup form .input-search span.fa.fa-search.mr-2 {
  margin-top: 4px;
}

.navbar .search-right .popup form .input-search input {
  width: 100%;
  border: none;
}

.navbar .search-right .popup form button {
  border-radius: 0px 30px 30px 0px;
}

.navbar .search-right .popup form button:hover {
  color: #fff;
  opacity: 0.8;
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
}

.navbar .search-right .popup form input:focus {
  outline: none;
  border: none;
}

.navbar .search-right .pop-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  transition: opacity 0ms;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  background: rgba(25, 23, 23, 0.81);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.85);
  height: 100vh;
}

.navbar .search-right .pop-overlay:target {
  visibility: visible;
  opacity: 1;
}

.navbar .search-right .popup {
  margin: 0rem auto;
  max-width: 800px;
  border-radius: 3px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 2em;
}

.navbar .search-right .search-top {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-gap: 30px;
  margin-top: 24px;
}

.navbar .search-right .search-top h4 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 16px;
}

.navbar .search-right .popup h3 {
  font-size: 6px;
  color: #fff;
  margin-bottom: 16px;
}

.navbar .search-right .search-top span.fa {
  padding-right: 10px;
}

.navbar .search-right .search-top li {
  display: block;
}

.navbar .search-right .search-top li a {
  font-size: 16px;
  color: #fff;
  line-height: 28px;
}

.navbar .search-right .search-top li a:hover {
  color: #fff;
}

.navbar .search-right .close {
  position: absolute;
  top: 60px;
  right: 40px;
  transition: all 200ms;
  font-size: 40px;
  text-decoration: none;
  color: #fff;
  opacity: 1;
  background: none;
  font-weight: 200;
}

.navbar .search-right .close:hover {
  opacity: 0.8;
  background: none;
}

@media only screen and (max-width: 1440px) {
  .navbar-expand-lg .navbar-nav .nav-item {
    padding: 0 10px;
  }

  .navbar-expand-lg .nav_btn > li {
    padding: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar-expand-lg .btn-gradient {
    padding: 10px 20px;
    font-size: 16px;
  }

  .navbar-expand-lg .navbar-nav.nav_btn > li {
    margin-left: 0px;
  }

  .navbar-expand-lg .navbar-nav.nav_btn a.btn {
    padding: 10px 20px;
  }

  .navbar-expand-lg .navbar-nav.nav_btn a {
    font-size: 16px;
    padding: 0px;
  }
}

.navbar-toggler {
  outline: 0 !important;
}

@media only screen and (max-width: 991px) {
  /* .navbar .navbar-brand img {
    max-width: 54px;
  } */

  .navbar-toggler {
    border: none;
    font-size: 24px;
  }

  .navbar .search-right {
    margin: 10px auto 0;
  }

  .navbar-nav {
    -webkit-animation-name: none;
    animation-name: none;
    float: left;
    text-align: center;
    width: 100%;
    background: var(--bg-color);
    padding: 20px;
    margin-top: 16px;
    box-shadow: 0 6px 19px rgba(0, 0, 0, 0.05);
  }

  .navbar-nav:last-child {
    border: 1px solid var(--border-color);
  }

  .navbar-expand-lg .navbar-nav .nav-item {
    -webkit-animation-name: none;
    animation-name: none;
    padding: 0;
  }

  .navbar-expand-lg .navbar-nav.nav_btn > li {
    padding-bottom: 10px;
  }

  .navbar-nav .dropdown-menu {
    text-align: center;
  }

  .navbar-nav .dropdown-menu .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .navbar-expand-lg .navbar-nav .nav-item .nav-link.active::before {
    bottom: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    display: inline-block;
    padding: 8px 0;
    text-align: center;
  }

  header {
    height: auto;
    padding: 17px 0;
  }

  .navbar {
    padding: 4px 0px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    transform: translateX(0px);
    border: 1px solid var(--border-color-light);
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu:before {
    border: 1px solid var(--border-color-light);
    border-right: none;
    border-bottom: none;
  }

  header.nav-fixed {
    height: auto;
    padding: 0px 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    display: block;
    color: var(--heading-color);
  }

  .navbar-toggler-icon {
    background-image: none;
    padding: 0;
    width: 34px;
    height: 34px;
    display: inline-block;
    background: #272121;
    border-radius: 4px;
    text-align: center;
  }

  .navbar-toggler-icon {
    outline: none;
    font-size: 20px;
    line-height: 1.2em;
    color: black;
    background: transparent;
    line-height: 34px;
  }

  .navbar-toggler {
    padding: 0rem 0rem !important;
  }
}

@media only screen and (max-width: 991px) {
  .allmock-top-header {
    position: relative;
    height: 73px;
  }

  .navbar-expand-lg a.nav-link.donate.btn.btn-style {
    display: inline-block;
    padding: 0px 40px;
  }

  button.navbar-toggler .icon-close {
    display: block;
  }

  button.navbar-toggler .icon-expand {
    display: none;
  }

  button.navbar-toggler.collapsed .icon-close {
    display: none;
  }

  button.navbar-toggler.collapsed .icon-expand {
    display: block;
  }

  .cont-ser-position {
    position: absolute;
    right: 60px;
    top: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar .navbar-brand {
    font-size: 28px;
  }

  a.navbar-brand i {
    font-size: 28px;
  }

  .navbar .search-right {
    margin: 0 6px;
  }

  .navbar form.search-box {
    display: block;
  }

  .navbar .search-right .popup form button {
    padding: 15px 40px;
    margin-left: 0;
    width: 100%;
    margin-top: 1em;
  }

  .navbar .search-right .popup form .input-search {
    padding: 16px 20px;
  }

  .navbar .search-right .popup {
    padding: 1em;
  }
}

@media only screen and (max-width: 380px) {
  a.navbar-brand i {
    font-size: 24px;
  }

  .navbar .search-right {
    margin: 0 0px;
  }

  .navbar .navbar-brand {
    font-size: 24px;
  }

  .navbar .navbar-brand img {
    width: 100%;
  }

  .navbar .navbar-brand {
    font-size: 24px;
  }
}

/* //navigation styling */
/*-- //header  --*/

/*-- button style --*/
.btn-primary {
  padding: 12px 30px;
  font-size: 16px;
  color: #fff;
  background: var(--secondary-color);
  border-radius: 30px;
  font-weight: 600;
}

.btn-primary-outline {
  padding: 12px 30px;
  font-size: 16px;
  color: var(--secondary-color);
  border-color: var(--secondary-color);
  border-radius: 30px;
  font-weight: 500;
}

/*-- //button style --*/
/*-- title style --*/
.title-heading-w3 {
  max-width: 700px;
}

h3.title-main {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

/*-- //title style --*/
/*-- banner --*/
.allmock-banner {
  background: url(../images/banner.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  /* min-height: 800px; */
  min-height: 95vh;
  display: flex;
  align-items: center;
}

.allmock-banner h3 {
  font-size: 40px;
  line-height: 53px;
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 1200px) {
  .allmock-banner h3 {
    font-size: 48px;
    line-height: 58px;
  }
}

@media (max-width: 991px) {
  .allmock-banner h3 {
    font-size: 38px;
    line-height: 48px;
  }

  .banner-left {
    padding: 0;
  }

  /* header for mobile */
  #site-header {
    background-color: #ff7214;
    padding: 10px 0;
  }
}

@media (max-width: 768px) {
  .banner-left {
    padding: 15px;
  }

  .allmock-banner h3 {
    font-size: 40px;
  }

  .banner-right img {
    width: 400px;
  }

  .btn-primary {
    padding: 10px 28px;
    font-size: 15px;
  }
}

@media (max-width: 410px) {
  .allmock-banner h3 {
    font-size: 35px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 360px) {
  .allmock-banner h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

/*-- //banner --*/
/*-- banner bottom section --*/
/* title style */
.title-main {
  max-width: 700px;
}

h3.title-big {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

/* //title style */
.allmock-index-block4 .feature-unit {
  border: 1px solid var(--border-color-light);
  padding: 3rem 4rem;
  background: var(--bg-light);
  transition: 0.3s ease-in;
  min-height: 400px;
}

.allmock-index-block4 .feature-unit:hover,
.allmock-index-block4 .feature-unit.active {
  transition: 0.3s ease-in;
  box-shadow: -1px 2px 12px -9px rgba(0, 0, 0, 0.41);
  background: var(--bg-color);
}

.features15-para h6 {
  font-weight: 600;
  color: var(--secondary-color);
}

.allmock-index-block4 .features15-col-text {
  margin-top: 30px;
}

.allmock-index-block4 .features15-para h4 {
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
}

.allmock-index-block4 .features15-para p {
  margin-top: 10px;
}

.allmock-index-block4 .features15-col-text:hover div.features15-para h4 {
  transition: 0.3s ease-in-out;
  color: var(--secondary-color);
}

@media screen and (max-width: 1200px) {
  h3.title-big {
    font-size: 35px;
    line-height: 45px;
  }

  .allmock-index-block4 .feature-unit {
    padding: 1.5rem 0.5rem;
  }

  p {
    line-height: 26px;
  }

  .allmock-index-block4 .features15-para h4 {
    font-size: 19px;
  }
}

@media screen and (max-width: 991px) {
  .allmock-index-block4 .features15-para h4 {
    font-size: 20px;
  }

  h3.title-big {
    font-size: 30px;
    line-height: 40px;
  }
}

.brand-btn-primary {
  border: none !important;
  color: white !important;
  background-color: #ff6600 !important;
}

/* //banner bottom section */
/* middle section */
.allmock-servicesblock {
  background: url(../images/middle.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-color: var(--bg-dark-color);
}

.left-wthree-img img {
  border-radius: 10px;
  box-shadow: 0px 78px 105px -52px #ffcdab;
}

.allmock-services-landing-color {
  background-color: var(--bg-color);
}

.about-right-faq h6 {
  margin-bottom: 6px;
}

@media screen and (max-width: 991px) {
  .left-wthree-img {
    order: 2;
  }

  .about-right-faq,
  .left-wthree-img {
    padding-left: 5em;
  }
}

@media screen and (max-width: 768px) {
  .about-right-faq,
  .left-wthree-img {
    padding-left: 1em;
  }
}

/* //middle section */
/* team section */
.allmock-teams-32-main h6 a.title-team-32 {
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  color: var(--heading-color);
  text-transform: uppercase;
}

.allmock-teams-32-main h6 a.title-team-32:hover {
  color: var(--secondary-color);
}

.allmock-teams-32-main p.sm-text-32 {
  font-size: 15px;
  line-height: 20px;
  margin: 2px 0 20px;
}

.allmock-teams-32-main .right-team-9 {
  background: var(--bg-color);
  padding: 15px 0;
  text-align: center;
}

.allmock-teams-32-main .column-19 img {
  width: 100%;
  transition: 0.5s all;
  -moz-transition: 0.5s all;
  border-radius: 10px;
}

.allmock-teams-32-main .column-19 img:hover {
  transform: translateY(-5px);
  box-shadow: 0px 17px 26px -16px rgba(0, 0, 0, 0.41);
}

@media (max-width: 991px) {
}

@media (max-width: 900px) {
}

@media (max-width: 480px) {
  .allmock-teams-32-main h6 a.title-team-32 {
    font-size: 18px;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .d-sm-none {
    display: none !important;
  }
}

@media (max-width: 410px) {
  .team-main-19 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* //team section */
/*-- testiomials --*/
.allmock-companies-hny-6 .cusrtomer-layout {
  background: var(--bg-light);
}

.allmock-companies-hny-6 .quote-special {
  position: absolute;
  top: 30%;
  left: 22%;
}

.allmock-companies-hny-6 .quote-special i {
  font-size: 170px;
  color: #f5f5f5;
}

.allmock-companies-hny-6 .testimonial {
  position: relative;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.allmock-companies-hny-6 .testimonial .peopl {
  margin-top: 1em;
}

.allmock-companies-hny-6 .testimonial img {
  max-width: 100%;
  vertical-align: middle;
}

.allmock-companies-hny-6 .testimonial p {
  font-size: 20px;
  line-height: 30px;
}

.allmock-companies-hny-6 .testimonial .peopl h3 {
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
}

.allmock-companies-hny-6 p.indentity {
  color: var(--secondary-color);
  font-size: 16px;
  margin-bottom: 18px;
}

.allmock-companies-hny-6 .owl-theme .owl-dots,
.allmock-companies-hny-6 .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin-top: 40px;
}

.allmock-companies-hny-6 .owl-theme .owl-nav {
  margin-top: 10px;
}

.allmock-companies-hny-6 .owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: 16px;
  margin: 5px;
  padding: 4px 7px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.allmock-companies-hny-6 .owl-theme .owl-nav [class*='owl-']:hover {
  background: #fff;
  color: #fff;
  text-decoration: none;
}

.allmock-companies-hny-6 .owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.allmock-companies-hny-6 .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0px;
  position: absolute;
  z-index: 9;
  left: 30px;
  right: 30px;
  bottom: 30px;
}

.allmock-companies-hny-6 .owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.allmock-companies-hny-6 .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 5px;
  background: #dedede;
  display: block;
  backface-visibility: visible;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
  outline: none;
  opacity: 0.6;
}

.allmock-companies-hny-6 .owl-theme .owl-dots .owl-dot.active span,
.allmock-companies-hny-6 .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--secondary-color);
  opacity: 1;
}

.allmock-companies-hny-6 .owl-carousel .owl-stage {
  padding-left: 0 !important;
}

@media (max-width: 991px) {
  .allmock-companies-hny-6 .owl-theme .owl-nav.disabled + .owl-dots {
    bottom: -37px;
  }
}

@media (max-width: 736px) {
  .allmock-companies-hny-6 .testimonial .peopl h3 {
    font-size: 18px;
  }
}

@media (max-width: 667px) {
  .allmock-companies-hny-6 .cusrtomer-layout {
    display: block;
    align-items: center;
  }

  .allmock-companies-hny-6 .testimonial .peopl h3 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  /* .allmock-companies-hny-6 .owl-theme .owl-dots,
  .allmock-companies-hny-6 .owl-theme .owl-nav {
    display: none;
  } */
}

.allmock-companies-hny-6 .owl-carousel,
.allmock-companies-hny-6 .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.allmock-companies-hny-6 .owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.allmock-companies-hny-6 .owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.allmock-companies-hny-6 .owl-carousel .owl-stage:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.allmock-companies-hny-6 .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.allmock-companies-hny-6 .owl-carousel .owl-item,
.allmock-companies-hny-6 .owl-carousel .owl-wrapper {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.allmock-companies-hny-6 .owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.allmock-companies-hny-6 .owl-carousel .owl-item .testimonial img {
  border-radius: 50%;
}

.allmock-companies-hny-6 .owl-carousel .owl-dots.disabled,
.allmock-companies-hny-6 .owl-carousel .owl-nav.disabled {
  display: none;
}

.allmock-companies-hny-6 .no-js .owl-carousel,
.allmock-companies-hny-6 .owl-carousel.owl-loaded {
  display: block;
}

.allmock-companies-hny-6 .owl-carousel .owl-dot,
.allmock-companies-hny-6 .owl-carousel .owl-nav .owl-next,
.allmock-companies-hny-6 .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.allmock-companies-hny-6 .owl-carousel .owl-nav button.owl-next,
.allmock-companies-hny-6 .owl-carousel .owl-nav button.owl-prev,
.allmock-companies-hny-6 .owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.allmock-companies-hny-6 .owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.allmock-companies-hny-6 .owl-carousel.owl-hidden {
  opacity: 0;
}

.allmock-companies-hny-6 .owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.allmock-companies-hny-6 .owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.allmock-companies-hny-6 .owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.allmock-companies-hny-6 .owl-carousel.owl-rtl {
  direction: rtl;
}

.allmock-companies-hny-6 .owl-carousel.owl-rtl .owl-item {
  float: right;
}

.allmock-companies-hny-6 .owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.allmock-companies-hny-6 .owl-carousel .owl-animated-in {
  z-index: 0;
}

.allmock-companies-hny-6 .owl-carousel .owl-animated-out {
  z-index: 1;
}

.allmock-companies-hny-6 .owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.allmock-companies-hny-6 .owl-height {
  transition: height 0.5s ease-in-out;
}

.allmock-companies-hny-6 .owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.allmock-companies-hny-6 .owl-carousel .owl-item .owl-lazy:not([src]),
.allmock-companies-hny-6 .owl-carousel .owl-item .owl-lazy[src^=''] {
  max-height: 0;
}

.allmock-companies-hny-6 .owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.allmock-companies-hny-6 .owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.allmock-companies-hny-6 .owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 1;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.allmock-companies-hny-6 .owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}

.allmock-companies-hny-6 .owl-carousel .owl-video-playing .owl-video-play-icon,
.allmock-companies-hny-6 .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.allmock-companies-hny-6 .owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.allmock-companies-hny-6 .owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*-- //testiomials --*/
/*-- blog section --*/
.allmock-grids-block-5 .grids5-info h4 a {
  color: var(--heading-color);
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
  display: block;
  font-weight: bold;
}

.allmock-grids-block-5 .grids5-info {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
}

.allmock-grids-block-5 .grids5-info:hover {
  transform: translateY(-5px);
  box-shadow: 0px 17px 26px -16px rgba(0, 0, 0, 0.41);
}

.allmock-grids-block-5 .grids5-info:hover h4 a,
.allmock-grids-block-5 .grids5-info h4 a:hover {
  color: var(--secondary-color);
}

.allmock-grids-block-5 .grids5-info a {
  display: block;
}

.allmock-grids-block-5 .grids5-info a img {
  display: block;
  background-color: #fdf2f6;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.allmock-grids-block-5 .grids5-info p {
  margin-top: 15px;
}

.allmock-grids-block-5 .blog-info {
  padding: 30px;
  background: var(--bg-color);
  border: 1px solid var(--border-color-light);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.allmock-grids-block-5 .grids5-info h5 {
  font-size: 16px;
  color: var(--para-color);
  line-height: 12px;
}

/* pagination blog page */
.pagination-style {
  margin-top: 4em;
  text-align: center;
  display: block;
}

.pagination-style ul li {
  display: inline-block;
}

.pagination-style ul li span.fa {
  color: var(--heading-color);
}

.pagination-style ul li a:hover span.fa {
  color: #fff;
}

.pagination-style a {
  display: inline-block;
  color: var(--heading-color);
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  font-weight: 600;
}

.pagination-style a span {
  margin: 0;
}

.pagination-style a.active,
.pagination-style a:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.pagination-style ul li a.not-allowed span.fa {
  cursor: not-allowed;
  color: #c3c2c2;
}

.pagination-style .not-allowed:hover {
  opacity: 1;
  background: transparent;
  color: #c3c2c2;
}

/* //pagination blog page */
@media all and (max-width: 800px) {
  .allmock-grids-block-5 .blog-info {
    padding: 20px;
  }
}

@media all and (max-width: 568px) {
  .allmock-grids-block-5 .grids5-info h4 a {
    margin-top: 13px;
  }

  .allmock-grids-block-5 .grids5-info p {
    margin-top: 12px;
  }
}

@media all and (max-width: 414px) {
  .allmock-grids-block-5 .wrapper {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .allmock-grids-block-5 .grids5-info h4 a {
    font-size: 23px;
  }
}

@media all and (max-width: 384px) {
  .allmock-grids-block-5 .grids5-info h4 a {
    font-size: 22px;
  }

  .pagination-style {
    margin-top: 2em;
  }
}

@media all and (max-width: 375px) {
  .allmock-grids-block-5 .grids5-info h4 a {
    font-size: 20px;
  }
}

/*-- //blog section --*/

/* footer */
.allmock-footer-22 .footer-sub {
  padding: 80px 0 0px 0px;
  background: var(--footer-color);
}

.right-side {
  padding: 50px;
  max-width: 700px;
  margin: 0 auto;
  border-radius: 10px;
  background: url(../images/banner.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: -100px;
  background-color: var(--bg-light);
  box-shadow: 0px -1px 41px 0px rgb(21 22 23 / 8%);
}

.allmock-footer-22 .sub-one-left h6,
.allmock-footer-22 .sub-two-right h6 {
  color: #eee;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(23, 54, 88, 0.4);
  padding-bottom: 10px;
}

.allmock-footer-22 .sub-two-right ul li {
  list-style: none;
}

.allmock-footer-22 .sub-two-right ul li span {
  margin-right: 10px;
  color: #fff;
}

.allmock-footer-22 .sub-one-left p,
.allmock-footer-22 .sub-two-right p {
  font-size: 16px;
  line-height: 24px;
  color: #cecece;
}

.allmock-footer-22 .sub-columns {
  margin-top: 120px;
}

.allmock-footer-22 ul.social li,
.allmock-footer-22 ul.jst-link li {
  display: inline-block;
  margin-right: 5px;
}

.allmock-footer-22 ul.social li a {
  color: #fff;
  padding: 0 15px;
  font-size: 18px;
  display: inline-block;
  text-align: center;
}

.allmock-footer-22 ul.social li a:hover {
  opacity: 0.8;
  transition: 0.3s ease;
}

.allmock-footer-22 ul.social li a span {
  font-size: 15px;
  line-height: 35px;
}

.allmock-footer-22 .columns-2 {
  margin-top: 1.2em;
}

.allmock-footer-22 .columns p,
.allmock-footer-22 .columns p a {
  font-size: 12px;
  line-height: 20px;
  color: #a0a0a0;
}

.allmock-footer-22 .sub-two-right ul li a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #cecece;
  margin: 0 0 10px 0;
  display: block;
}

.allmock-footer-22 .columns p a {
  color: #fff;
}

.allmock-footer-22 .columns p a:hover {
  color: #fff;
  text-decoration: underline;
}

.allmock-footer-22 .right-side h4 {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.allmock-footer-22 .copyright-footer {
  background: var(--footer-cpy-color);
  padding: 20px 0;
}

.allmock-footer-22 .column2 span {
  font-size: 18px;
  color: #fff;
  vertical-align: middle;
  margin-right: 10px;
  width: 25px;
  text-align: center;
}

.allmock-footer-22 .column2 a {
  text-decoration: none;
  color: #cecece;
  line-height: 25px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
}

.allmock-footer-22 .href2 {
  margin-top: 10px;
  padding: 10px 0;
}

.allmock-footer-22 .contact-para {
  color: #cecece;
  line-height: 25px;
  font-size: 17px;
  margin-top: 10px;
}

/* for mobile device */

@media (max-width: 1080px) {
  .allmock-footer-22 .text-txt {
    grid-gap: 50px;
  }
}

@media (max-width: 991px) {
  .allmock-footer-22 .text-txt {
    grid-template-columns: 1fr;
  }

  .allmock-footer-22 .footer {
    padding: 60px 0;
  }

  .allmock-footer-22 .href2 {
    padding: 6px 0;
  }
}

@media (max-width: 800px) {
  .allmock-footer-22 .subscride-link h4 {
    margin-top: 60px;
  }

  .allmock-footer-22 .right-side h4 {
    font-size: 28px;
    line-height: 33px;
  }

  .allmock-footer-22 .sub-columns {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .allmock-footer-22 .columns.text-right {
    text-align: center !important;
  }

  .allmock-footer-22 .copyright-footer .text-left,
  .allmock-footer-22 .copyright-footer .text-right {
    text-align: center;
  }
}

@media (max-width: 736px) {
  .allmock-footer-22 .sub-one-left h6,
  .allmock-footer-22 .sub-two-right h6 {
    margin-bottom: 10px;
    padding-bottom: 7px;
  }

  .allmock-footer-22 .footer-sub {
    padding: 40px 0;
  }
}

@media (max-width: 736px) {
  .right-side {
    margin: 0 1em;
  }

  .footer-sub {
    margin-top: 2em;
  }
}

@media (max-width: 568px) {
  .allmock-footer-22 .subscride-link h4 {
    margin-top: 50px;
  }

  .allmock-footer-22 .text-txt {
    grid-gap: 40px;
  }

  .allmock-footer-22 .sub-columns {
    margin-top: 100px;
  }

  .footer-sub {
    margin-top: 1em;
  }
}

@media (max-width: 520px) {
  .allmock-footer-22 .href2 {
    padding: 4px 0;
  }
}

@media (max-width: 440px) {
  .allmock-footer-22 .right-side h4 {
    font-size: 25px;
  }
}

@media (max-width: 375px) {
  .right-side {
    padding: 35px 20px;
  }

  .footer-sub {
    margin-top: 0em;
  }
}

/* //footer */

/* about page */
/* inner-banner */
.inner-banner {
  background: url(../images/banner.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  min-height: 300px;
  background-color: var(--bg-light);
}

.allmock-breadcrumb {
  display: block;
  text-align: center;
  margin: 0 auto;
  z-index: 100;
  padding-top: 150px;
  z-index: 99;
}

.breadcrumbs-custom-path {
  text-align: center;
  display: inline-block;
}

.breadcrumbs-custom-path li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  line-height: 1.5;
  text-transform: capitalize;
}

.breadcrumbs-custom-path li span.fa {
  font-size: 12px;
}

.breadcrumbs-custom-path a,
.breadcrumbs-custom-path a:active,
.breadcrumbs-custom-path a:focus {
  color: var(--secondary-color);
}

@media (max-width: 800px) {
  .inner-banner {
    min-height: 260px;
  }

  .allmock-breadcrumb {
    padding-top: 120px;
  }

  .breadcrumbs-custom-path li {
    font-size: 16px;
  }
}

@media (max-width: 568px) {
  .inner-banner {
    min-height: 200px;
  }

  .breadcrumbs-custom-path li {
    font-size: 15px;
  }

  .allmock-breadcrumb {
    padding-top: 100px;
  }
}

/* //inner-banner */
/* contact page */
.form-allmocks {
  background: var(--bg-dark-color);
}

.main-grid-contact {
  box-shadow: 0px -1px 41px 0px rgb(21 22 23 / 8%);
}

.content-form-right input,
.content-form-right textarea {
  outline: none;
  color: #222;
  font-size: 15px;
  border: none;
  padding: 14px;
  border: 1px solid var(--border-color-light);
  width: 100%;
  background: var(--bg-color);
  height: auto;
  border-radius: 4px;
}

.content-form-right textarea {
  min-height: 10em;
  resize: none;
}

/* map */
.map {
  padding: 0;
}

.map iframe {
  border: none;
  width: 100%;
  min-height: 574px;
}

/* support */
.support-grid span {
  font-size: 60px;
  color: var(--secondary-color);
}

.support-grid h5 {
  font-weight: 600;
}

@media all and (max-width: 991px) {
  .map iframe {
    min-height: 420px;
  }

  .map {
    order: 2;
  }
}

/* //contact page */
/* about page */
/* about section */
.left-img img {
  border-radius: 10px;
}

.allmock-text-6 .text-6-mian h2 {
  font-size: 35px;
  line-height: 40px;
  font-weight: 600;
}

.allmock-text-6 .text-6-mian h2 span {
  font-weight: 300;
}

.allmock-text-6 .text-6-mian h6 {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 8px 8px 8px 15px;
  border-left: 2px solid var(--secondary-color);
}

.allmock-text-6 .text-6-mian p {
  margin-top: 15px;
}

@media screen and (max-width: 991px) {
  .left-img {
    order: 2;
  }

  .text-6-info {
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  .allmock-text-6 .text-6-mian h2 {
    font-size: 34px;
  }
}

@media screen and (max-width: 480px) {
  .allmock-text-6 .text-6-mian p {
    margin: 12px 0 0;
  }
}

@media screen and (max-width: 387px) {
  .allmock-text-6 .text-6-mian h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .allmock-text-6 .text-6-mian p {
    font-size: 16px;
  }

  .allmock-text-6 .text-6-mian h6 {
    font-size: 16px;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 376px) {
  .allmock-text-6 .text-6-mian h2 {
    font-size: 30px;
    line-height: 38px;
  }
}

@media screen and (max-width: 322px) {
  .allmock-text-6 .text-6-mian h2 {
    font-size: 30px;
    line-height: 35px;
  }
}

/* //about section */
.about-block-3 {
  background: var(--bg-light);
}

.about-block-3 h6.top-grid-text label {
  color: var(--secondary-color);
  margin-right: 10px;
}

.about-block-3 h6.top-grid-text {
  font-size: 16px;
  color: #737373;
}

.about-block-3 h3.grid-one-text {
  font-size: 24px;
}

/* stats */
.w3_stats {
  /* background: url(../images/error.png) no-repeat center; */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
}

.w3_stats h3.title-big {
  color: #fff;
}

.w3_stats h6.sub-title {
  color: #eee;
}

.w3-stats p {
  font-size: 1.1em;
}

.timer {
  font-size: 3em;
  font-weight: 600;
  color: var(--secondary-color);
}

p.count-text {
  text-transform: uppercase;
  color: var(--heading-color);
  font-weight: 600;
}

@media (max-width: 991px) {
  .timer {
    font-size: 2.5em;
  }

  p.count-text {
    font-size: 12px;
  }

  .counter img {
    width: 100px;
  }
}

@media (max-width: 736px) {
  p.count-text {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  p.count-text {
    font-size: 13px;
    line-height: 20px;
    margin-top: 10px;
  }
}

/* //stats */
/* //about page */
/* courses pages */
/* video section */
h3.title-big {
  font-size: 32px;
  line-height: 55px;
  font-weight: 600;
}

.video-section ul.allmock-right-book li span {
  font-size: 12px;
  margin-right: 10px;
  opacity: 0.4;
}

.video-section .play-view {
  left: 0;
  right: 0;
  top: calc(50% - 40px);
}

.video-section span.video-play-icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 35px;
  padding-left: 10px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  -webkit-animation: ripple 1s linear infinite;
  animation: ripple 1s linear infinite;
  color: transparent;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: #fff;
  border: 4px solid #fff;
  background: rgb(12 12 12 / 22%);
}

.video-section span.video-play-icon:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

/* .video-section img {
  width: 100%;
} */

@media (max-width: 1200px) {
  h3.title-big {
    font-size: 35px;
    line-height: 50px;
  }
}

@media (max-width: 480px) {
  h3.title-big {
    font-size: 32px;
    line-height: 44px;
  }
}

@media (max-width: 375px) {
  h3.title-big {
    font-size: 28px;
    line-height: 38px;
  }
}

.box-light-blue {
  background: #67c3cd4d !important;
}

.bottom-right-radius {
  border-radius: 10px 10px 150px 10px;
}

.bottom-left-radius {
  border-radius: 10px 10px 10px 150px;
}

.top-right-radius {
  border-radius: 10px 150px 10px 10px;
}

.top-left-radius {
  border-radius: 150px 10px 10px 10px;
}

.light-blue {
  background: #67c3cd;
}

.dark-blue {
  background: #517dff;
}

.dark-yellow {
  background: #ffa250;
}

.box-dark-yellow {
  background: #ffa2501c !important;
}

.box-dark-blue {
  background: #517dff38 !important;
}

.dark-purple {
  background: #9e78fb;
}

.box-dark-purple {
  background: #9e78fb33 !important;
}

.icon-container {
  width: 92px;
  text-align: center;
  padding: 18px;
  border-radius: 5px;
  box-shadow: 0px 24px 16px 1px #4b494921;
  margin-bottom: 30px;
}

#owl-demo .item {
  margin: 3px;
}

#owl-demo .item img {
  display: block;
  width: 100%;
  height: auto;
}

.team .owl-stage-outer {
  padding-top: 20px;
  padding-bottom: 0px;
}

.team .owl-dots {
  margin-top: 0px !important;
}

.team-shadow .item {
  padding: 10px;
}

.team .right-team-9 {
  background: var(--bg-color);
  padding: 15px 0;
  text-align: center;
}

.title-team-32 {
  color: black;
}

.team-shadow .item .item-content {
  box-shadow: 0px 0px 15px 0px #dbdbdb;
}

.owl-carousel-testimonial .item .item-content {
  border: none !important;
}

.team .item .item-content {
  /* border: 1px solid #DBDBDB; */
  border-radius: 19px;
  padding: 13px;
  z-index: 1111;
  position: relative;
}

.footer-navigation a {
  padding: 0 15px;
}

.teacher-section {
  background-color: #fff0e5;
  padding: 70px 0;
}

/* test for you styles */

.test-price {
  position: absolute;
  top: 14px;
  left: 10px;
  background: #ffe9d9;
  border-radius: 19px;
  font-size: 19px;
  padding: 5px 19px;
  font-weight: 700;
}

.test-star {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 16px;
  font-weight: 500;
  background: #000000af;
  padding: 5px 13px 8px;
  border-radius: 8px;
  color: #ffffff;
}

.complete-test {
  position: absolute;
  left: 10px;
  font-size: 1.4em;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 1px 1px 3px black;
  bottom: 20px;
}

.client-card {
  box-shadow: 4px 7px 4px 0px #c5bfbf;
}

.client-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

.sort-title {
  color: gray;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 16px;
}

.client-img {
  padding: 5px;
  width: 75px;
  vertical-align: top;
}

.shop-test {
  margin: 10px;
  width: 250px;
  height: 72px;
  font-size: 21px;
  font-weight: 400;
  line-height: 165%;
}

.shop-box {
  background: #ffffff;
  box-shadow: 2px 2px 15px rgba(89, 89, 89, 0.25);
  border-radius: 6px;
  min-height: 300px;
  font-size: 15px;
}

.shop-img {
  padding: 8px;
}

.bg-orange {
  background-color: var(--orange);
  border: none;
}

.color-orange {
  color: var(--orange);
}

.top-banner {
  background-image: url('../images/top-banner.png');
  min-height: 500px;
  background-size: cover;
}

.sort-banner {
  min-height: 200px !important;
}

.page-top-banner,
.page-top-banner .navbar {
  box-shadow: none !important;
  background-color: transparent !important;
  padding-top: 7px !important;
}

.page-top-banner .logo-oragne {
  display: none !important;
}

.page-top-banner .navbar .nav-link {
  color: var(--white) !important;
}

.page-top-banner .logo-oragne {
  display: none;
}

.page-top-banner .logo-white {
  display: block !important;
}

.top-banner-title {
  color: var(--white);
  font-size: 40px;
  font-weight: 700;
}

.sub-title {
  color: gray;
}

.top-banner-sub-title {
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}

.learn-something {
  margin-top: 25px;
  font-family: montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #252a34;
  opacity: 0.5;
}

.about-content {
  font-size: 16px;
}

.about-content li {
  font-weight: 500;
  color: #000;
}

.about-image {
  width: 50%;
}

.about-box .learning-statistic {
  text-align: center;
  background: #fff0e5;
  padding: 25px 5px;
  margin: 10px 0;
}

.test-description {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-left: 15px;
  color: #555555;
}

.brand-btn-primary {
  border: none !important;
  color: white !important;
  background-color: #ff6600 !important;
}

.blog-learn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 165.5%;
  color: #ff6600;
}

.blog-detail-img {
  margin-top: 10px;
  margin-bottom: 25px;
}

.blog-detail-content p {
  font-size: 16px;
  font-weight: 400;
}

.blog-detail-content li {
  font-size: 16px;
  font-weight: 400;
}

.blog-detail-content h5 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 25px;
}

.contant-box {
  background: #fff0e5;
  padding: 25px;
  border-left: 14px solid #ff6600;
}

.footer-navigation a {
  color: white;
}

.apply-coupon .card-body {
  padding: 5px;
}

.apply-coupon input {
  background: #f5f5f5;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 5px;
  margin-top: 2px;
}

.total-basket .card {
  margin-top: 25px;
  background: #fbe3c7;
  border-radius: 5px;
}

.total-basket h6 {
  font-family: 'Hind Kochi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  color: #474747;
  text-align: center;
}

.total-basket a {
  width: 400px;
  background: #ff6600;
  border-radius: 5px;
}

.cart-subtotal {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #474747;
  border-bottom: 1px solid #adadad;
}

.cart-order-total {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #474747;
}

.error-img {
  text-align: center;
}

.error-container {
  background-image: url('../images/error_background.png');
}

.error-msg h3 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #263238;
}

.error-msg h3 span {
  color: #ff6600;
}

.error-button {
  margin-top: 25px;
  text-align: center;
}

.error-button .btn {
  font-weight: 700;
  font-size: 15px;
  background: #ff6600;
  color: aliceblue;
  border: 0;
  border-radius: 10px;
  padding: 15px;
}

.fc-white {
  color: white !important;
}

.text {
  font-size: 1rem;
  color: grey;
  font-weight: 400;
}

.text-cta {
  font-size: 1rem;
  color: white;
  font-weight: 600;
}

.section-heading {
  font-size: 18px;
  font-weight: 500;
  color: black;
}
.item-content-package {
  margin-top: 100px;
}
a.nav-link.active {
  color: #ff6600 !important;
  font-weight: 600;
}
.rounded-lg {
  border-radius: 15px;
}

.rounded-md {
  border-radius: 10px;
}

.form-check-input:checked {
  background-color: #ff6600;
  border-color: #ff6600;
}

.brand-color {
  color: #ff6600;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* //video section */

/*# sourceMappingURL=style-freedom.css.map */